import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import cardano_logo from '../assets/images/common/cardanologo.png';
import Button from '../components/button/index';
import data from '../assets/data/data-nft';
import { useAssets, useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryPotentialRewardsWithPending } from '../api/GraphQL/StakeProject/query.ts';
import { mutateCreateStakeTransaction, mutateSubmitStakeTransaction } from '../api/GraphQL/Transaction/Stake/mutation.ts';
import Swal from 'sweetalert2';
import { color } from "highcharts";

function Stake() {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
    const [stakeComponents, setStakeComponents] = useState([]);
    const [isStakingNFTs, setIsStakingNFTs] = useState(false);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [assetStaked, setStakedNFTs] = useState([]);
    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [stakedNFTsRewardInput, setStakedNFTsRewardInput] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedMainNFTCount, setSelectedMainNFTCount] = useState(0);
    const [selectedPairNFTCount, setSelectedPairNFTCount] = useState(0);
    const [isDisplayingPairs, setIsDisplayingPairs] = useState(false);

    const [policyIdFilter, setPolicyIdFilter] = useState(null);

    const handleFilterButtonClick = (policyId) => {
        setPolicyIdFilter(policyId);
    };

    const isMainnet = true;
    const isLocalProject = false;


    let apiKey = '';
    let stakeProjectId = '';
    let nftDomain = '';

    let maxSelectedNFTs = 9;

    if (isMainnet) {
        if (isLocalProject) {
            apiKey = "iDMPxHZeZPuKBuBhufIQlpXCExz6ETp8RD4gOJVfokc=";
            stakeProjectId = "736f0c55-a73c-4552-a3d7-b4070a1510a0";
            nftDomain = 'kwic';
        } else {
            apiKey = process.env.REACT_APP_API_KEY;
            stakeProjectId = "736f0c55-a73c-4552-a3d7-b4070a1510a0";
            nftDomain = 'kwic';
        }
    } else {
        apiKey = "7FoxfBgV2k+RSz6UUts3/fG1edG7oIGXxdtIVCdalaI=";
        nftDomain = 'preprod';
        stakeProjectId = "e04fe028-74ec-48ff-b2e6-e2741d7a8b52";
    }

    const stakeNFTs = async (event) => {
        setIsStakingNFTs(true);
        await stakeNFT(event);
        setIsStakingNFTs(false);
    };

    const handleNFTClick = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);
        const isAlreadySelectedPolicy = selectedPolicyIds.includes(policyId);

        if (isAlreadySelected) {
            // Deselect the NFT
            setSelectedNFTs(selectedNFTs.filter((selectedAddress) => selectedAddress !== assetName));
            setSelectedPolicyIds(selectedPolicyIds.filter((selectedAddress) => selectedAddress !== policyId));

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);
            
             // Define a mapping of parameters and their replacement values
             const replacements = {
                "000dfffd40": "000de140",
            };

            // Your original string
            const originalHex = assetNameToHex;

            // Create a regex pattern that matches any of the parameters to be replaced
            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

            // Replace the matched parameters with their corresponding values
            const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);

            // Remove the corresponding stakeComponent from stakeComponents
            setStakeComponents((prevStakeComponents) =>
                prevStakeComponents.filter((stakeComponent) => stakeComponent.assetName !== replacedHex)
            );

            if (isDisplayingPairs) {
                setSelectedPairNFTCount((prevCount) => prevCount - 1);
            } else {
                setSelectedMainNFTCount((prevCount) => prevCount - 1);
            }
        } else {
            // Select the NFT
            setSelectedNFTs([...selectedNFTs, assetName]);
            if (!selectedPolicyIds.includes(policyId)) {
                setSelectedPolicyIds([...selectedPolicyIds, policyId]);
            }


            if (isDisplayingPairs) {
                setSelectedPairNFTCount((prevCount) => prevCount + 1);
            } else {
                setSelectedMainNFTCount((prevCount) => prevCount + 1);
            }

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);
            
             // Define a mapping of parameters and their replacement values
             const replacements = {
                "000dfffd40": "000de140",
            };

            // Your original string
            const originalHex = assetNameToHex;

            // Create a regex pattern that matches any of the parameters to be replaced
            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

            // Replace the matched parameters with their corresponding values
            const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);
            // Create a new stakeComponent and add it to stakeComponents
            const newStakeComponent = {
                stakeProjectId: stakeProjectId,
                policyId: policyId,
                assetName: replacedHex,
            };

            setStakeComponents((prevStakeComponents) => [...prevStakeComponents, newStakeComponent]);
        }

    };

    const assets = useAssets();

    const { wallet, connected } = useWallet();

    const stakeNFT = async (event) => {
        event.preventDefault();
        try {
            let timerInterval
            Swal.fire({
                title: 'PROCESSING!',
                html: 'CHECKING TRANSACTION <b></b>',
                timer: 0,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {

                }
            });

            const addresses = await wallet.getChangeAddress();
            const paymentAddresses = addresses;

            const createInput = {
                paymentAddress: paymentAddresses,
                stakeComponents: stakeComponents,
                unstakeComponents: [],
                addStakeTokenComponents: [],
            };

            //Create the Submission Object
            const createTransaction = await mutateCreateStakeTransaction(createInput);
            const submitSuccesses = [];
            for (let i = 0; i < createTransaction.successTransactions.length; i++) {
                const transactionId = createTransaction.successTransactions[i].transactionId;
                const hexTransaction = createTransaction.successTransactions[i].hexTransaction;

                // Sign the hex transaction
                const signedTx = await wallet.signTx(hexTransaction, true);

                const submitSuccess = {
                    transactionId: transactionId,
                    hexTransaction: signedTx,
                };
                submitSuccesses.push(submitSuccess);
            }

            //Submit the transaction
            const submitInput = {
                paymentAddress: paymentAddresses,
                successTransactions: submitSuccesses,
            };

            const submitTransaction = await mutateSubmitStakeTransaction(submitInput);

            const transactionIds = submitTransaction?.transactionIds;
            if (!transactionIds || transactionIds.length <= 0 || !!submitTransaction.error) {
                return submitTransaction.error;
            }

            Swal.fire({
                title: 'STAKING SUCCESSFUL!',
                width: 600,
                padding: '3em',
                color: '#FFF',
                background: '#0007FF url(/images/trees.png)',
                allowOutsideClick: false,
                allowEscapeKey: false,
                backdrop: `
                    rgba(0,0,0,0.8)
                    url("/images/nyan-cat.gif")
                    left top
                    no-repeat
                `,
                showConfirmButton: true, // Show the "OK" button
            }).then((result) => {
                if (result.isConfirmed) {
                    /* eslint-disable no-restricted-globals */
                    location.reload(); // Reload the page when the user clicks "OK"
                }
            });
            return Swal;
        } catch (error) {
            const errorMatch = error.message.match(/{"code":(\d+),/);

            if (errorMatch) {
                const errorCode = parseInt(errorMatch[1]);

                if (errorCode === 2) {
                    Swal.fire({
                        title: 'TRANSACTION CANCELLED!',
                        html: 'YOU CANCELLED THE TRANSACTION <b></b>',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                          rgba(0,0,0,0.8)
                          url("/images/nyan-cat.gif")
                          left top
                          no-repeat
                        `
                    }).then((result) => {
                        if (result.isConfirmed) {
                            /* eslint-disable no-restricted-globals */
                            location.reload(); // Reload the page when the user clicks "OK"
                        }
                    });
                }
            } else {
                Swal.fire({
                    title: 'AN ERROR OCCURED!',
                    html: 'PLEASE TRY AGAIN LATER <b></b>',
                    width: 600,
                    padding: '3em',
                    color: '#FFF',
                    background: '#0007FF url(/images/trees.png)',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    backdrop: `
                    rgba(0,0,0,0.8)
                    url("/images/nyan-cat.gif")
                    left top
                    no-repeat
                    `
                });
                // Handle the case where the error message format is unexpected
            }
        }
    };


    let targetPolicyIds = [];
    let defaultPolicyId;
    let excludedPolicyId;
    let allowedAssets;
    let collectionType = [];
    let collectionLinks = [];
    let rewardsPerHour = [];
    let filteredAssets = [];

    if (isMainnet) {
        defaultPolicyId = 'c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312';
        excludedPolicyId = 'b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115';
        targetPolicyIds = ["c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312", "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115"];
        allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
        collectionType = {
            "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "KWIC OG",
            "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115": "KWIC PLAYABLE",
        };
        rewardsPerHour = {
            "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "30 $KWIC/DAY",
            "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115": "$KWIC MULTIPLIER",
        };

    } else {
        defaultPolicyId = '612cc2c8dccb72811bb01f2a4d56e4d1aa1e7f0fca10874c18883c1f';
        excludedPolicyId = '520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210';
        targetPolicyIds = ["612cc2c8dccb72811bb01f2a4d56e4d1aa1e7f0fca10874c18883c1f", "520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210"];
        allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
        collectionType = {
            "612cc2c8dccb72811bb01f2a4d56e4d1aa1e7f0fca10874c18883c1f": "KWIC OG",
            "520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210": "KWIC PLAYABLE",

        };
        rewardsPerHour = {
            "612cc2c8dccb72811bb01f2a4d56e4d1aa1e7f0fca10874c18883c1f": "30 $KWIC/DAY",
            "520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210": "$KWIC MULTIPLIER",
        };
    }

    allowedAssets.forEach((item) => {
        function stringToHex(inputString) {
            let hexString = '';
            for (let i = 0; i < inputString.length; i++) {
                const hex = inputString.charCodeAt(i).toString(16);
                hexString += hex.length === 1 ? '0' + hex : hex;
            }
            return hexString;
        }

        const assetNameToHex = stringToHex(item.assetName);



        // Define a mapping of parameters and their replacement values
        const replacements = {
            "000dfffd40": "000de140",
        };

        // Your original string
        const originalHex = assetNameToHex;

        // Create a regex pattern that matches any of the parameters to be replaced
        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

        // Replace the matched parameters with their corresponding values
        const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);
        // Push the staked NFT to the array

        stakedNFTsRewardInput.push({ policyId: item.policyId, assetName: replacedHex });
    });

    filteredAssets = allowedAssets.filter((asset) => !stakedNFTsArray.includes(asset.assetName));

    const handleMultiplierButtonClick = () => {
        // Call the first onClick event
        handleFilterButtonClick(excludedPolicyId);
        setIsDisplayingPairs(true);
    };

    const handleOgButtonClick = () => {
        // Call the first onClick event
        handleFilterButtonClick(defaultPolicyId);
        setIsDisplayingPairs(false);
    };

    const onLoadDelay = async (event) => {
        // Add a delay of 1 second (adjust as needed)
        await new Promise(resolve => setTimeout(resolve, 8000));

        // Call the function you want to execute after the delay
        getStakedNFTs();
        handleFilterButtonClick(defaultPolicyId);
    };

    const getStakedNFTs = async (event) => {
        setIsGettingStakedNfts(true);
        setIsLoading(true);
        await getStakedNFTsFunction(event);
        setIsGettingStakedNfts(false);
        setIsLoading(false);
    };

    const getStakedNFTsFunction = async (event) => {
        try {

            const GetPotentialRewardsInput = {
                stakeProjectId: stakeProjectId,
                nftStakeRewardWithPendingInputs: stakedNFTsRewardInput,
            };

            const result = await queryPotentialRewardsWithPending(GetPotentialRewardsInput);

            result.forEach((item) => {
                function hexToString(hex) {
                    let string = '';
                    for (let i = 0; i < hex.length; i += 2) {
                        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                    }
                    return string;
                }

                const stringAssetNames = hexToString(item.assetName);

                // Define a mapping of parameters and their replacement values
                const replacements = {
                    "á@": "�@",
                };

                // Your original string
                const originalString = stringAssetNames;

                // Create a regex pattern that matches any of the parameters to be replaced
                const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                // Replace the matched parameters with their corresponding values
                const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                // Push the staked NFT to the array
                stakedNFTsArray.push(replacedString);
            });
        } catch (error) {

        }
    };

    // Effect to listen for changes in assets
    useEffect(() => {
        onLoadDelay();
    }, []);


    return (
        <div className='inner-page'>
            <section className="tf-section project_2">
                {connected ? (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tf-title left mb-400 mt-40">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            {isDisplayingPairs ? (
                                                <>
                                                    <Link onClick={handleOgButtonClick}><h4 style={{ color: '#FF0175' }}>← BACK TO OG'S SELECTION</h4></Link>
                                                    <h3 className="title">
                                                        SELECT MULTIPLIER NFT'S
                                                    </h3>

                                                    {selectedPairNFTCount > selectedMainNFTCount ? (
                                                        <h4 className="title">
                                                            CAN SELECT UP TO <span className="selected_num">{selectedMainNFTCount}</span> {selectedMainNFTCount === 1 ? ('MULTIPLIER'):('MULTIPLIERS')}<br></br>
                                                            SELECTED MULTIPLIERS: <span className="selected_num">{selectedPairNFTCount}</span>
                                                        </h4>
                                                    ) : (
                                                        <h4 className="title">
                                                            CAN SELECT UP TO <span className="selected_num">{selectedMainNFTCount}</span> {selectedMainNFTCount === 1 ? ('MULTIPLIER'):('MULTIPLIERS')}<br></br>
                                                            SELECTED MULTIPLIERS: <span className="selected_num">{selectedPairNFTCount}</span>
                                                        </h4>
                                                    )}

                                                </>

                                            ) : (
                                                <>
                                                    <h3 className="title">
                                                        SELECT KWIC OG NFT'S
                                                    </h3>

                                                    <h4 className="title">
                                                        SELECTED OG'S: <span className="selected_num">{selectedMainNFTCount}</span>
                                                    </h4>
                                                </>

                                            )}


                                        </div>
                                        <div className="col-lg-2 page-button">
                                            {selectedMainNFTCount === 0 ? (
                                                //<Button title="MULTIPLIERS" onClick={getStakedNFTs} addclass='stake_btn' />
                                                <Button title="MULTIPLIERS" addclass='stake_btn disabled' />
                                            ) : (
                                                isDisplayingPairs ? (
                                                    selectedMainNFTCount === selectedPairNFTCount ? (
                                                        <Button title="STAKE" onClick={stakeNFTs} addclass='stake_btn' />
                                                    ) : (
                                                        <>
                                                            <Button title="STAKE" addclass='stake_btn disabled' />
                                                        </>

                                                    )
                                                ) : (
                                                    <Button title="MULTIPLIERS" onClick={handleMultiplierButtonClick} addclass='stake_btn' />
                                                )
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isLoading ? (
                                <div>
                                    <div className="col-lg-12 mb-100">
                                        <div className="no-assets">
                                            <span className="loader"></span><br></br>
                                            <h4>CHECKING WALLET</h4>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                isDisplayingPairs ? (
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            {filteredAssets
                                                .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                .length === 0 ? (
                                                <div className="no-available-asset mb-100">
                                                    <h3 className="text-center no-nft">NO AVAILABLE NFT'S</h3>
                                                </div>
                                            ) : (
                                                filteredAssets
                                                    .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                    .map((asset) => {
                                                        const collectionResult = collectionType[asset.policyId];
                                                        const rewardsResult = rewardsPerHour[asset.policyId];

                                                        const domain = nftDomain;
                                                        const key = CryptoJS.enc.Base64.parse(apiKey);
                                                        const token = asset.fingerprint;

                                                        function buildUrl(domain, token, uri, params) {
                                                            const searchParams = new URLSearchParams(params);
                                                            return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                        }

                                                        function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                            params.tk = "";
                                                            let url = buildUrl(domain, token, uri, params);
                                                            params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                            return buildUrl(domain, token, uri, params);
                                                        }

                                                        const originalImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 512 });

                                                        // Define a mapping of parameters and their replacement values
                                                        const replacements = {
                                                            "KWIC": "#",
                                                            "Playable": "",
                                                            "�@": "",
                                                            "Avatar": "",
                                                            "-": "",
                                                            "#": "",
                                                            " ": "",
                                                        };

                                                        // Your original string
                                                        const originalString = asset.assetName;

                                                        // Create a regex pattern that matches any of the parameters to be replaced
                                                        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                                                        // Replace the matched parameters with their corresponding values
                                                        const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                                                        //{originalImageUrl}

                                                        return (
                                                            <React.Fragment key={asset.assetName}>
                                                                <div className="project-box-style7">
                                                                    {selectedPairNFTCount === selectedMainNFTCount && !selectedNFTs.includes(asset.assetName) ? (
                                                                        <>
                                                                            <div className="nft_disabled"></div>
                                                                            <div className="nft_selection_full" >
                                                                                <div className="image">
                                                                                    <img src={originalImageUrl} alt="" />
                                                                                </div>
                                                                                <div className="content">
                                                                                    <h5 className="heading">{replacedString}</h5>
                                                                                    <ul>
                                                                                        <li>
                                                                                            <p>{rewardsResult}</p>
                                                                                        </li>
                                                                                        <li>
                                                                                            <p>{collectionResult}</p>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <div className="content-progress-box style2">
                                                                                        <div className="progress-bar" data-percentage="100%">
                                                                                            <div className="progress-title-holder">
                                                                                                <span className="progress-title">
                                                                                                    <span className="progress_number">
                                                                                                        <span className="progress-number-mark"></span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <Link
                                                                            key={asset.unit}
                                                                            alt={asset.assetName}
                                                                            className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`}
                                                                            onClick={() => handleNFTClick(asset.assetName, asset.policyId)}
                                                                        >
                                                                            <div className="image">
                                                                                <img src={originalImageUrl} alt="" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h5 className="heading">{replacedString}</h5>
                                                                                <ul>
                                                                                    <li>
                                                                                        <p>{rewardsResult}</p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>{collectionResult}</p>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="content-progress-box style2">
                                                                                    <div className="progress-bar" data-percentage="100%">
                                                                                        <div className="progress-title-holder">
                                                                                            <span className="progress-title">
                                                                                                <span className="progress_number">
                                                                                                    <span className="progress-number-mark"></span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            {filteredAssets
                                                .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                .length === 0 ? (
                                                <div className="no-available-asset mb-100">
                                                    <h3 className="text-center no-nft">NO AVAILABLE NFT'S</h3>
                                                </div>
                                            ) : (
                                                filteredAssets
                                                    .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                    .map((asset) => {
                                                        const collectionResult = collectionType[asset.policyId];
                                                        const rewardsResult = rewardsPerHour[asset.policyId];

                                                        const domain = nftDomain;
                                                        const key = CryptoJS.enc.Base64.parse(apiKey);
                                                        const token = asset.fingerprint;

                                                        function buildUrl(domain, token, uri, params) {
                                                            const searchParams = new URLSearchParams(params);
                                                            return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                        }

                                                        function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                            params.tk = "";
                                                            let url = buildUrl(domain, token, uri, params);
                                                            params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                            return buildUrl(domain, token, uri, params);
                                                        }

                                                        const originalImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 512 });

                                                        // Define a mapping of parameters and their replacement values
                                                        const replacements = {
                                                            "KWIC": "#",
                                                            "�@": "",
                                                        };

                                                        // Your original string
                                                        const originalString = asset.assetName;

                                                        // Create a regex pattern that matches any of the parameters to be replaced
                                                        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                                                        // Replace the matched parameters with their corresponding values
                                                        const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                                                        //{originalImageUrl}

                                                        return (
                                                            <div className="project-box-style7" key={asset.unit}>
                                                                {selectedMainNFTCount === maxSelectedNFTs && !selectedNFTs.includes(asset.assetName) ? (
                                                                    <>
                                                                        <div className="nft_disabled"></div>
                                                                        <div className="nft_selection_full" >
                                                                            <div className="image" >
                                                                                <img src={originalImageUrl} alt="" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h5 className="heading">{replacedString}</h5>
                                                                                <ul>
                                                                                    <li>
                                                                                        <p>{rewardsResult}</p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>{collectionResult}</p>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="content-progress-box style2">
                                                                                    <div className="progress-bar" data-percentage="100%">
                                                                                        <div className="progress-title-holder">
                                                                                            <span className="progress-title">
                                                                                                <span className="progress_number">
                                                                                                    <span className="progress-number-mark">

                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <Link key={asset.unit} alt={asset.assetName} className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`} onClick={() => handleNFTClick(asset.assetName, asset.policyId)} >
                                                                        <div className="image" >
                                                                            <img src={originalImageUrl} alt="" />
                                                                        </div>
                                                                        <div className="content">
                                                                            <h5 className="heading">{replacedString}</h5>
                                                                            <ul>
                                                                                <li>
                                                                                    <p>{rewardsResult}</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>{collectionResult}</p>
                                                                                </li>
                                                                            </ul>
                                                                            <div className="content-progress-box style2">
                                                                                <div className="progress-bar" data-percentage="100%">
                                                                                    <div className="progress-title-holder">
                                                                                        <span className="progress-title">
                                                                                            <span className="progress_number">
                                                                                                <span className="progress-number-mark">

                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                            </div>
                                                        );
                                                    })
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="container mb-100">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project_wrapper_2">
                                    <div className="no-wallet-connected"> <h2>CONNECT TO A WALLET</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );

}

export default Stake;