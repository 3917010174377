import Home from "./Home";
import Forge from "./Forge";
import Stake from "./Stake";
import StakeMultiplier from "./StakeMultiplier";
import Stabilise from "./Stabilise";
import Summon from "./Summon";
import Multipliers from "./Multipliers";
import SummonCommonHTML from "./SummonCommonHTML";
import SummonUncommonHTML from "./SummonUncommonHTML";
import SummonRareHTML from "./SummonRareHTML";
import SummonLegendaryHTML from "./SummonLegendaryHTML";


const routes = [
  
  { path: '/', component: <Home />},
  { path: '/staking', component: <Stake />},
  { path: '/staking_multiplier', component: <StakeMultiplier />},
  { path: '/stabilise', component: <Stabilise />},
  { path: '/multipliers', component: <Multipliers />},
  { path: '/forge', component: <Forge />},
  { path: '/summon', component: <Summon />},
  { path: '/summon/68746d6c2f636f6d6d6f6e', component: <SummonCommonHTML />},
  { path: '/summon/68746d6c2f756e636f6d6d6f6e', component: <SummonUncommonHTML />},
  { path: '/summon/68746d6c2f72617265', component: <SummonRareHTML />},
  { path: '/summon/68746d6c2f6c6567656e64617279', component: <SummonLegendaryHTML />},
]

export default routes;