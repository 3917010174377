import React from 'react';
import { Link } from 'react-router-dom';

function Forge() {
    return (
        <div className='inner-page'>
            <section className="tf-section tf-wallet" data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">
                <div className="container">
                    <div className="row">   
                        <div className="col-lg-12 center">
                            <img className="img_forge mt-40" src={require ("../assets/images/common/comingsoon.PNG")} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Forge;