import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/header';
import { MeshProvider } from "@meshsdk/react";
import 'url-search-params-polyfill';
import './App.scss';
import Footer from './components/footer';

function App() {
  const isMaintenance = false;
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, [])


  return (
    <>
      <MeshProvider>
        {isMaintenance ? (<>
          <div className='header-fixed main home3 counter-scroll' data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">
            <section className="home-section" data-aos-delay="200" data-aos="fade-up" data-aos-duration="200">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 center">
                    <h1>UNDER MAINTENANCE</h1>
                    <h4>KWIC DASHBOARD WILL BE BACK SHORTLY.</h4>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>) : (<>
          <Header />
          <Routes>
            {
              routes.map((data, idx) => (
                <Route key={idx} path={data.path} element={data.component} exact />
              ))
            }
          </Routes>
        </>
        )}
      </MeshProvider>
      <Footer />
    </>
  );
}

export default App;