import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import cardano_logo from '../assets/images/common/cardanologo.png';
import Button from '../components/button/index';
import data from '../assets/data/data-nft';
import { useAssets, useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryStakedNfts } from '../api/GraphQL/StakeProject/query.ts';
import { CreateStakeTransactionPayload } from '../types/Transactions/StakeTransaction/CreateStakeTransactionPayload.d.ts';
import { mutateCreateSingleOrBulkBurnTransaction, mutateSubmitSingleOrBulkBurnTransaction } from '../api/GraphQL/Transaction/Stake/mutation.ts';
import Swal from 'sweetalert2';

function Stake() {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
    const [stakeComponents, setStakeComponents] = useState([]);
    const [isStakingNFTs, setIsStakingNFTs] = useState(false);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [assetStaked, setStakedNFTs] = useState([]);
    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [metadata, setMetadata] = useState({});

    /**
    const isMainnet = true;
    const isLocalProject = false;

    
    let apiKey = '';
    let stakeProjectId = '';
    let nftDomain = '';

    if (isMainnet) {
        if (isLocalProject) {
            apiKey = "iDMPxHZeZPuKBuBhufIQlpXCExz6ETp8RD4gOJVfokc=";
            nftDomain = 'kwic';
        } else {
            apiKey = process.env.REACT_APP_API_KEY;
            nftDomain = 'kwic';
        }
    } else {
        apiKey = "7FoxfBgV2k+RSz6UUts3/fG1edG7oIGXxdtIVCdalaI=";
        nftDomain = 'preprod';
    }

    const summonNFTs = async (event) => {
        setIsStakingNFTs(true);
        await summonNFT(event);
        setIsStakingNFTs(false);
    };

    const handleNFTClick = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);
        const isAlreadySelectedPolicy = selectedPolicyIds.includes(policyId);

        if (isAlreadySelected) {
            // Deselect the NFT
            setSelectedNFTs(selectedNFTs.filter((selectedAddress) => selectedAddress !== assetName));
            setSelectedPolicyIds(selectedPolicyIds.filter((selectedAddress) => selectedAddress !== policyId));

            // Remove the corresponding stakeComponent from stakeComponents
            setStakeComponents((prevStakeComponents) =>
                prevStakeComponents.filter((stakeComponent) => stakeComponent.assetName !== assetName)
            );
        } else {
            // Select the NFT
            setSelectedNFTs([...selectedNFTs, assetName]);
            if (!selectedPolicyIds.includes(policyId)) {
                setSelectedPolicyIds([...selectedPolicyIds, policyId]);
            }

            // Create a new stakeComponent and add it to stakeComponents
            const newStakeComponent = {
                stakeProjectId: stakeProjectId,
                policyId: policyId,
                assetName: assetName,
            };

            setStakeComponents((prevStakeComponents) => [...prevStakeComponents, newStakeComponent]);
        }
    };

    const assets = useAssets();

    */

    const { wallet, connected } = useWallet();

    /**
    const summonNFT = async (event) => {
        event.preventDefault();
        try {
            let timerInterval
            Swal.fire({
                title: 'PROCESSING!',
                html: 'CHECKING TRANSACTION <b></b>',
                timer: 0,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {

                }
            });

            const addresses = await wallet.getChangeAddress();
            const paymentAddresses = addresses;

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            // Assuming selectedNFTs is an array of strings
            const selectedNFTsHex = selectedNFTs.map(stringToHex);


            const selectedPolicyIdsString = selectedPolicyIds.join(',');
            const nftLists = ['ce3284fb-7bd0-4426-a3d0-79fb635ad801', '2be7594d-6083-4d19-a2f6-f85870e1b55c'];

            const createInput = {
                nftIds: nftLists,
                nftProjectId: 'b758d03d-d2a7-4df5-90e1-24179982a786',
                paymentAddress: paymentAddresses,
            };

            //Create the Submission Object
            const createTransaction = await mutateCreateSingleOrBulkBurnTransaction(createInput);
            console.log(createTransaction);

            /*
            const submitSuccesses = [];

            for (let i = 0; i < createTransaction.length; i++) {
                const hexTransaction = createTransaction[i].hexTransaction;

                // Sign the hex transaction
                const signedTx = await wallet.signTx(hexTransaction, true);

                const submitSuccess = {
                    hexTransaction: signedTx,
                };
                submitSuccesses.push(submitSuccess);
            }

            //Submit the transaction
            const submitInput = {
                hexTransaction: submitSuccesses,
                nftIds: 'ce3284fb-7bd0-4426-a3d0-79fb635ad801',
                nftProjectId: 'b758d03d-d2a7-4df5-90e1-24179982a786',
                paymentAddress: paymentAddresses,
            };

            const submitTransaction = await mutateSubmitSingleOrBulkBurnTransaction(submitInput);
            console.log(submitTransaction);
            


            return Swal;
        } catch (error) {
            Swal.fire({
                title: 'AN ERROR OCCURED!',
                html: 'PLEASE TRY AGAIN LATER <b></b>',
                width: 500,
                padding: '3em',
                border: '#FF0175',
                color: '#FFF',
                background: '#0007FF url(/images/trees.png)',
                backdrop: `
                  rgba(0,0,0,0.8)
                  url("/images/nyan-cat.gif")
                  left top
                  no-repeat
                `
            });
        }
    };

    let targetPolicyIds = [];
    let allowedAssets;
    let collectionType = [];

    if (isMainnet) {
        targetPolicyIds = ["b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115"];

        allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
        collectionType = {
            "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115": "KWIC - PLAYABLE AVATARS",
        };
    } else {
        targetPolicyIds = ["520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210"];

        allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
        collectionType = {
            "520fbce5d651055877fb2a71bb6dbd0fded891562f0f1c0fba1a1210": "KWIC - PLAYABLE AVATARS",
        };
    }
    */
    const getStakedNFTs = async (event) => {
        //setIsGettingStakedNfts(true);
        setIsLoading(true);
        //await getStakedNFTsFunction(event);
        //setIsGettingStakedNfts(false);
        setIsLoading(false);
    };
    /*
    const getStakedNFTsFunction = async (event) => {
        try {
            const result = await queryStakedNfts(stakeProjectId);

            // Extract the assetName values from the result and populate the object
            result.forEach((item) => {
                function hexToString(hex) {
                    let string = '';
                    for (let i = 0; i < hex.length; i += 2) {
                        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                    }
                    return string;
                }

                const stringAssetNames = hexToString(item.assetName);

                // Push the staked NFT to the array
                stakedNFTsArray.push(stringAssetNames);
            });
        } catch (error) {
            console.log(error);
            return []; // Return an empty array in case of an error
        }
    };
    */

    useEffect(() => {
        // This code will run when the component is mounted (page load)
        getStakedNFTs(); // Call your function here
    }, []);

    return (
        <div className='inner-page'>
            <section className="tf-section project_2">
                {connected ? (
                    <div className="container">
                        <div className="row">
                            {isLoading ? (
                                <div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-100">
                                            <div className="row">
                                                <div className="col-lg-12 mt-100 mb-100">
                                                    <h3 className="title center mt-40">
                                                        <span className="loader"></span><br />
                                                        LOADING
                                                    </h3>
                                                    <h4 className="title center">
                                                        CHECKING ASSETS

                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <h4 className="text-center">LEGENDARY SUMMON</h4>
                                    <h2 className="text-center">HIGH TEMPLE OF MONITOR LIZARDS (HTML)</h2>
                                    <div className="row">
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-6">
                                            <div className="project-box-style9">
                                                <div alt="html" className="nft_selection_full" >
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>750%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>LEGENDARY</h4>
                                                            <h5>0/15</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmTcLYBbSUx37BhQhVMu97vWCbybtgxtEFeFtrhuDzWPa5?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3"></div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-40">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <h3 className="title">
                                                        SELECT NFT'S TO SACRIFICE
                                                    </h3>
                                                    <h4 className="title">
                                                        SELECTED NFT'S: <span className="selected_num">{selectedNFTs.length}</span>
                                                    </h4>
                                                </div>
                                                <div className="col-lg-2">
                                                    {selectedNFTs.length === 0 ? (
                                                        <Button title="SACRIFICE" addclass='stake_btn disabled' />
                                                    ) : (
                                                        <Button title="SACRIFICE" addclass='stake_btn disabled' />
                                                        //<Button title="SACRIFICE" onClick={summonNFTs} addclass='stake_btn' />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            <div className="no-available-asset">
                                                <h3 className="text-center no-nft">SUMMON WILL BE AVAILABLE SOON</h3>
                                            </div>
                                            {/**
                                            {allowedAssets.filter((asset) => !stakedNFTsArray.includes(asset.assetName))
                                                .map((asset) => {
                                                    const collectionResult = collectionType[asset.policyId];
                                                    const domain = "preprod";
                                                    const key = CryptoJS.enc.Base64.parse(apiKey);
                                                    const token = asset.fingerprint;

                                                    function buildUrl(domain, token, uri, params) {
                                                        const searchParams = new URLSearchParams(params);
                                                        return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                    }

                                                    function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                        params.tk = "";
                                                        let url = buildUrl(domain, token, uri, params);
                                                        params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                        return buildUrl(domain, token, uri, params);
                                                    }

                                                    const originalImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 1024 });

                                                    // Define a mapping of parameters and their replacement values
                                                    const replacements = {
                                                        "KWIC": "#",
                                                        "Playable": "",
                                                        "�@": "",
                                                        "Avatar": "",
                                                        "-": "",
                                                        // Add more mappings as needed
                                                    };

                                                    // Your original string
                                                    const originalString = asset.assetName;

                                                    // Create a regex pattern that matches any of the parameters to be replaced
                                                    const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                                                    // Replace the matched parameters with their corresponding values
                                                    const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                                                    //{originalImageUrl}

                                                    const nftMetaData = nftcdnUrl(domain, key, token, "/metadata");

                                                    let metadataValue = null;
                                                    let avatarClass = '';
                                                    let getAvatarClassValue = ''; // Initialize getAvatarClassValue as an array

                                                    const fetchAvatarClass = async () => {
                                                        try {
                                                            const response = await fetch(nftMetaData);
                                                            if (!response.ok) {
                                                                // Handle errors
                                                            }
                                                            const metadata = await response.json();

                                                            metadataValue = metadata.metadata;
                                                            avatarClass = metadataValue ? metadataValue.Class : null;
                                                            return avatarClass;
                                                        } catch (error) {
                                                            console.error('Error fetching metadata:', error);
                                                            return null;
                                                        }
                                                    };

                                                    // You can't directly assign the result to avatarClassValue because fetchAvatarClass is asynchronous
                                                    // Use async/await or .then() to get the result
                                                    (async () => {
                                                        const avatarClassValue = await fetchAvatarClass();
                                                        getAvatarClassValue = avatarClassValue;
                                                    })();

                                                    return (
                                                        <div className="project-box-style7" key={asset.unit}>
                                                            <Link key={asset.unit} alt={asset.assetName} className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`} onClick={() => handleNFTClick(asset.assetName, asset.policyId)} >
                                                                <div className="image" >
                                                                    <img src={originalImageUrl} alt="" />
                                                                </div>
                                                                <div className="content">
                                                                    <h5 className="heading">{replacedString}</h5>
                                                                    <ul>
                                                                        <li>
                                                                            <p>{collectionResult}</p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="content-progress-box style2">
                                                                        <div className="progress-bar" data-percentage="100%">
                                                                            <div className="progress-title-holder">
                                                                                <span className="progress-title">
                                                                                    <span className="progress_number">
                                                                                        <span className="progress-number-mark">

                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bg_style">
                                                                        <div className="image_inner">
                                                                            <img src={require("../assets/images/backgroup/bg_project_2.png")} alt="" />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}
                                                
                                            {allowedAssets
                                                .filter((asset) => !stakedNFTsArray.includes(asset.assetName))
                                                .length === 0 &&
                                                <div className="no-available-asset">
                                                    <h3 className="text-center no-nft">NO AVAILABLE NFT'S</h3>
                                                </div>
                                            }
                                            */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project_wrapper_2">
                                    <div className="no-assets"> <h2>CONNECT TO A WALLET</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );

}

export default Stake;

