import React , { useState , useEffect } from 'react';
import { Link , NavLink} from 'react-router-dom';
import menus from '../../pages/menu';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import { CardanoWallet} from '@meshsdk/react';
import CardanoWalletButton from '../CardanoWallet/CardanoWallet';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(false);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(() => {
        // Get the active index from local storage or use null if not available
        const savedIndex = localStorage.getItem('activeMenuIndex');
        return savedIndex ? parseInt(savedIndex) : null;
    });

    const handleDropdown = index => {
        //setMenuActive(!menuActive);
        setActiveIndex(index);

        // Save the active index in local storage
        localStorage.setItem('activeMenuIndex', index);
    };

    const handleLogoClick = () => {
        // Clear the active index in local storage
        localStorage.removeItem('activeMenuIndex');
        
        // Reset the activeIndex to null
        setActiveIndex(null);
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
             <div className="container">
            <div id="site-header-inner">
                <div className="header__logo">
                    <NavLink to="/" onClick={handleLogoClick}><img src={logo} alt="KWICDashboard" /></NavLink>
                </div>
                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                    <ul id="menu-primary-menu" className="menu">
                        {
                            menus.map((data,idx) => (
                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                    <Link to={data.links}>{data.name}</Link>
                                    {
                                        data.namesub &&
                                        <ul className="sub-menu">
                                            {
                                                data.namesub.map((submenu) => (
                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links} onClick={handleDropdown}>{submenu.sub}</NavLink></li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </nav>
                <div className="connect-wallet-btn-container">
                            <CardanoWalletButton />
                        </div>
                
                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
            </div>
        </div> 
        
        </header> 
 
        
    );
}

export default Header;