import React  from 'react';
import './styles.scss'


function Banner () {
    return (

        <div className="page-title st3">
            <div className="slider-main">
                <div className="slider-st3">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="image">
                                    <img className="img_main" src={require ("../../assets/images/common/kwic_token.gif")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Banner;