import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import cardano_logo from '../assets/images/common/cardanologo.png';
import Button from '../components/button/index';
import data from '../assets/data/data-nft';
import { useAssets, useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryStakedNfts} from '../api/GraphQL/StakeProject/query.ts';
import { CreateStakeTransactionPayload } from '../types/Transactions/StakeTransaction/CreateStakeTransactionPayload.d.ts';
import { mutateCreateStakeTransaction, mutateSubmitStakeTransaction } from '../api/GraphQL/Transaction/Stake/mutation.ts';
import Swal from 'sweetalert2';

function Stake  () {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
    const [stakeComponents, setStakeComponents] = useState([]);
    const [isStakingNFTs, setIsStakingNFTs] = useState(false);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [assetStaked, setStakedNFTs] = useState([]);
    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const assets = useAssets();

    const { wallet, connected } = useWallet();


    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
          }, 5000); 
    }, []);
    
    return (
        <div className='inner-page'>
            <section className="tf-section project_2">
                {connected ? (
                    <div className="container">
                        <div className="row">
                            {isLoading ? (
                                <div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-100">
                                            <div className="row">
                                                <div className="col-lg-12 mt-100 mb-100">
                                                    <h3 className="title center mt-40">
                                                    <span className="loader"></span><br/>
                                                        LOADING
                                                    </h3>
                                                    <h4 className="title center">
                                                        INITIALIZING AVAILABLE SUMMONS
                                                        
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-40">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="title">
                                                        SELECT SUMMON
                                                    </h3>
                                                    <h4 className="title">
                                                        SELECT THE NFT YOU WANTED TO SUMMON!
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            <div className="project-box-style8">
                                                <Link alt="html" className="nft_selection_full" to='68746d6c2f636f6d6d6f6e'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>400%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>COMMON</h4>
                                                            <h5>0/120</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmX7Md9SJRuLBbmLcANqbc3nhiajc5yCWLZeAaEXgjtJcv?_gl=1*108pknp*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3Mi41MC4wLjA.' alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">HIGH TEMPLE OF MONITOR LIZARDS</h5>
                                                        <ul>
                                                            <li><p>COMMON SACRIFICE (BURN)</p></li>
                                                            <li>1x COMMON IRONHEART</li>
                                                            <li>1x COMMON STRONGSHELL</li>
                                                            <li>1x COMMON WISEYE</li>
                                                            <li>1x COMMON SWIFTSTRIKER</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>COMMON SUMMON (MINT)</p></li>
                                                            <li>1x COMMON HTML/WRAPTAIL</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>MAX COMMON SACRIFICE TO SUMMON SUPPLY</p></li>
                                                            <li>480 COMMON KWIC - PLAYABLE AVATARS BURNT (120 OF EACH CLASS) = 120 COMMON HTML KWIC - PLAYABLE AVATARS MINTED</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className="project-box-style8">
                                                <Link alt="html" className="nft_selection_full" to='68746d6c2f756e636f6d6d6f6e'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>500%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>UNCOMMON</h4>
                                                            <h5>0/75</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmY45ntsYCh1ggFcozcoGDr2LLfNjr2Vz3wKjdZDPpnE4Z?_gl=1*1l3yrgx*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Ni41Ni4wLjA.' alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">HIGH TEMPLE OF MONITOR LIZARDS</h5>
                                                        <ul>
                                                            <li><p>UNCOMMON SACRIFICE (BURN)</p></li>
                                                            <li>1x UNCOMMON IRONHEART</li>
                                                            <li>1x UNCOMMON STRONGSHELL</li>
                                                            <li>1x UNCOMMON WISEYE</li>
                                                            <li>1x UNCOMMON SWIFTSTRIKER</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>UNCOMMON SUMMON (MINT)</p></li>
                                                            <li>1x UNCOMMON HTML/WRAPTAIL</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>MAX UNCOMMON SACRIFICE TO SUMMON SUPPLY</p></li>
                                                            <li>300 UNCOMMON KWIC - PLAYABLE AVATARS BURNT (75 OF EACH CLASS) = 75 UNCOMMON HTML KWIC - PLAYABLE AVATARS MINTED</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                            </div>
                                            
                                            <div className="project-box-style8">
                                                <Link alt="html" className="nft_selection_full" to='68746d6c2f72617265'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>600%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>RARE</h4>
                                                            <h5>0/40</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmXvfiYTA34FP1X1MrxaqbbS6rtPYWndE9VKqMTrc9uQ46?_gl=1*1386zis*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3MC41Mi4wLjA.' alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">HIGH TEMPLE OF MONITOR LIZARDS</h5>
                                                        <ul>
                                                            <li><p>RARE SACRIFICE (BURN)</p></li>
                                                            <li>1x RARE IRONHEART</li>
                                                            <li>1x RARE STRONGSHELL</li>
                                                            <li>1x RARE WISEYE</li>
                                                            <li>1x RARE SWIFTSTRIKER</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>RARE SUMMON (MINT)</p></li>
                                                            <li>1x RARE HTML/WRAPTAIL</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>MAX RARE SACRIFICE TO SUMMON SUPPLY</p></li>
                                                            <li>160 RARE KWIC - PLAYABLE AVATARS BURNT (40 OF EACH CLASS) = 40 RARE HTML KWIC - PLAYABLE AVATARS MINTED</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className="project-box-style8">
                                                <Link alt="html" className="nft_selection_full" to='68746d6c2f6c6567656e64617279'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>750%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>LEGENDARY</h4>
                                                            <h5>0/15</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmTcLYBbSUx37BhQhVMu97vWCbybtgxtEFeFtrhuDzWPa5?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">HIGH TEMPLE OF MONITOR LIZARDS</h5>
                                                        <ul>
                                                            <li><p>LEGENDARY SACRIFICE (BURN)</p></li>
                                                            <li>1x LEGENDARY IRONHEART</li>
                                                            <li>1x LEGENDARY STRONGSHELL</li>
                                                            <li>1x LEGENDARY WISEYE</li>
                                                            <li>1x LEGENDARY SWIFTSTRIKER</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>LEGENDARY SUMMON (MINT)</p></li>
                                                            <li>1x LEGENDARY HTML/WRAPTAIL</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>MAX LEGENDARY SACRIFICE TO SUMMON SUPPLY</p></li>
                                                            <li>60 LEGENDARY KWIC - PLAYABLE AVATARS BURNT (15 OF EACH CLASS) = 15 RARE HTML KWIC - PLAYABLE AVATARS MINTED</li>
                                                        </ul>
                                                    </div>
                                                </Link>
                                            </div>


                                            <div className="project-box-style8">
                                            <div className="nft_disabled"></div>
                                              <h3 className="nft_stabilizing">AVAILABLE SOON</h3>
                                                <div alt="html" className="nft_selection_full" >
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>1500%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>COMMON</h4>
                                                            <h5>0/40</h5>
                                                        </div>
                                                        <img src={require ("../assets/images/common/Reaper.gif")} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">REAPER</h5>
                                                        <ul>
                                                            <li><p>COMMON SACRIFICE (BURN)</p></li>
                                                            <li>1x OF EACH COMMON MAC MILITIA, BEST FRIENDOS, CEPHALOPOD SQUAD, BRAINWASHED BUNCH, UNIVERSAL MIND CTRL, HOUSE OF WALKIE, MIKE MOB, CUBE CORPS, BLOCKBUSTA BUDDIES, SECRET HAUSE OF INTERNET TROLLS, REFORMED REBELS, PHO_NES NETWORK, BERSERKER GUERILLAS, ????, BUCKETHEAD BLITZ.</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>COMMON SUMMON (MINT)</p></li>
                                                            <li>1x COMMON REAPER</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="project-box-style8">
                                            <div className="nft_disabled"></div>
                                              <h3 className="nft_stabilizing">AVAILABLE SOON</h3>
                                                <div alt="html" className="nft_selection_full" >
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>1650%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>UNCOMMON</h4>
                                                            <h5>0/30</h5>
                                                        </div>
                                                        <img src={require ("../assets/images/common/ReaperUncommon.gif")} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">REAPER</h5>
                                                        <ul>
                                                            <li><p>UNCOMMON SACRIFICE (BURN)</p></li>
                                                            <li>1x OF EACH UNCOMMON MAC MILITIA, BEST FRIENDOS, CEPHALOPOD SQUAD, BRAINWASHED BUNCH, UNIVERSAL MIND CTRL, HOUSE OF WALKIE, MIKE MOB, CUBE CORPS, BLOCKBUSTA BUDDIES, SECRET HAUSE OF INTERNET TROLLS, REFORMED REBELS, PHO_NES NETWORK, BERSERKER GUERILLAS, ????, BUCKETHEAD BLITZ.</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>UNCOMMON SUMMON (MINT)</p></li>
                                                            <li>1x UNCOMMON REAPER</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="project-box-style8">
                                            <div className="nft_disabled"></div>
                                              <h3 className="nft_stabilizing">AVAILABLE SOON</h3>
                                                <div alt="html" className="nft_selection_full"  >
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>1800%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>RARE</h4>
                                                            <h5>0/20</h5>
                                                        </div>
                                                        <img src={require ("../assets/images/common/ReaperRare.gif")} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">REAPER</h5>
                                                        <ul>
                                                            <li><p>RARE SACRIFICE (BURN)</p></li>
                                                            <li>1x OF EACH RARE MAC MILITIA, BEST FRIENDOS, CEPHALOPOD SQUAD, BRAINWASHED BUNCH, UNIVERSAL MIND CTRL, HOUSE OF WALKIE, MIKE MOB, CUBE CORPS, BLOCKBUSTA BUDDIES, SECRET HAUSE OF INTERNET TROLLS, REFORMED REBELS, PHO_NES NETWORK, BERSERKER GUERILLAS, ????, BUCKETHEAD BLITZ.</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>RARE SUMMON (MINT)</p></li>
                                                            <li>1x RARE REAPER</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="project-box-style8">
                                            <div className="nft_disabled"></div>
                                              <h3 className="nft_stabilizing">AVAILABLE SOON</h3>
                                                <div alt="html" className="nft_selection_full" >
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>2000%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>LEGENDARY</h4>
                                                            <h5>0/10</h5>
                                                        </div>
                                                        <img src={require ("../assets/images/common/ReaperLegendary.gif")} alt="" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">REAPER</h5>
                                                        <ul>
                                                            <li><p>LEGENDARY SACRIFICE (BURN)</p></li>
                                                            <li>1x OF EACH LEGENDARY MAC MILITIA, BEST FRIENDOS, CEPHALOPOD SQUAD, BRAINWASHED BUNCH, UNIVERSAL MIND CTRL, HOUSE OF WALKIE, MIKE MOB, CUBE CORPS, BLOCKBUSTA BUDDIES, SECRET HAUSE OF INTERNET TROLLS, REFORMED REBELS, PHO_NES NETWORK, BERSERKER GUERILLAS, ????, BUCKETHEAD BLITZ.</li>
                                                        </ul>
                                                        <ul>
                                                            <li><p>LEGENDARY SUMMON (MINT)</p></li>
                                                            <li>1x LEGENDARY REAPER</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="container mb-100">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project_wrapper_2">
                                    <div className="no-wallet-connected"> <h2>CONNECT TO A WALLET</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );

}

export default Stake;

