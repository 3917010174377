const menus = [
    {
        id: 1,
        name: 'STAKING',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'BASIC STAKE',
                links: 'staking'
            },
            {
                id: 2,
                sub: 'STAKE WITH MULTIPLIER',
                links: 'staking_multiplier'
            },
        ]
    },
    {
        id: 2,
        name: 'STABILISING',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'STABILISE',
                links: 'stabilise'
            },
            {
                id: 2,
                sub: 'UNLINK',
                links: 'multipliers'
            },
        ]
    },
    {
        id: 3,
        name: 'FORGE',
        links: 'forge',
    },
    {
        id: 4,
        name: 'SUMMON',
        links: 'summon',
    },
    
]

export default menus;