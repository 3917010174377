import { GraphQLClient, gql } from 'graphql-request';
import { CreateStakeTransactionInput } from '../../../../types/Transactions/StakeTransaction/CreateStakeTransactionInput';
import { CreateStakeTransactionPayload } from '../../../../types/Transactions/StakeTransaction/CreateStakeTransactionPayload';
import { CreateSingleOrBulkBurnTransactionInput } from '../../../../types/Transactions/BurnTransaction/CreateSingleOrBulkBurnTransactionInput';
import { CreateSingleOrBulkBurnTransactionPayload } from '../../../../types/Transactions/BurnTransaction/CreateSingleOrBulkBurnTransactionPayload';
import { SubmitSingleOrBulkBurnTransactionInput } from '../../../../types/Transactions/BurnTransaction/SubmitSingleOrBulkBurnTransactionInput';
import { SubmitSingleOrBulkBurnTransactionPayload } from '../../../../types/Transactions/BurnTransaction/SubmitSingleOrBulkBurnTransactionPayload';
import { SubmitStakeTransactionInput } from '../../../../types/Transactions/StakeTransaction/SubmitStakeTransactionInput';
import { SubmitStakeTransactionPayload } from '../../../../types/Transactions/StakeTransaction/SubmitStakeTransactionPayload';


//---------------------------------------------------------------------------------------------------//
// Stake Transaction Functions
//---------------------------------------------------------------------------------------------------//
//export const graphQLClient = new GraphQLClient("https://api.preprod.saturnnft.io/graphql/");
export const graphQLClient = new GraphQLClient("https://api.saturnnft.io/graphql/");
//export const graphQLClient = new GraphQLClient("https://localhost:5001/graphql/");

export const mutateCreateStakeTransaction = async (input: CreateStakeTransactionInput) => {
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            mutation CreateStakeTransaction($input: CreateStakeTransactionInput!) {
                createStakeTransaction(input: $input) {
                    successTransactions {
                        transactionId
                        hexTransaction
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );
    const createStakeTransactionPayload: CreateStakeTransactionPayload = response?.createStakeTransaction || {};
    return createStakeTransactionPayload;
};

export const mutateSubmitStakeTransaction = async (input: SubmitStakeTransactionInput) => {
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            mutation SubmitStakeTransaction($input: SubmitStakeTransactionInput!) {
                submitStakeTransaction(input: $input) {
                    transactionIds
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );
    const submitStakeTransactionPayload: SubmitStakeTransactionPayload = response?.submitStakeTransaction || {};
    return submitStakeTransactionPayload;
};
//---------------------------------------------------------------------------------------------------//

export const getGraphQLHeaders = () => {
    const headers: any = {} as HeadersInit;
    try {
        const apiKey = '0ddafc61-5193-4b62-8573-722b147d94b4-0c29cfbc-9781-425e-97ed-cf013459e987';
        headers['Authorization'] = `Bearer ${apiKey}`;
        return headers;
    } catch (error) {
        console.error(error);
        return headers;
    }
};

//---------------------------------------------------------------------------------------------------//
// Single Or Bulk NFT Burning Functions
//---------------------------------------------------------------------------------------------------//
export const mutateCreateSingleOrBulkBurnTransaction = async (input: CreateSingleOrBulkBurnTransactionInput) => {
    const parameters = { input: input };
    graphQLClient.setHeaders(getGraphQLHeaders());
    const response = await graphQLClient.request(
        gql`
            mutation CreateSingleOrBulkBurnTransaction($input: CreateSingleOrBulkBurnTransactionInput!) {
                createSingleOrBulkBurnTransaction(input: $input) {
                    hexTransaction
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const createSingleOrBulkBurnTransactionPayload: CreateSingleOrBulkBurnTransactionPayload =
        response?.createSingleOrBulkBurnTransaction || {};
    return createSingleOrBulkBurnTransactionPayload;
};

export const mutateSubmitSingleOrBulkBurnTransaction = async (input: SubmitSingleOrBulkBurnTransactionInput) => {
    const parameters = { input: input };
    graphQLClient.setHeaders(getGraphQLHeaders());
    const response = await graphQLClient.request(
        gql`
            mutation SubmitSingleOrBulkBurnTransaction($input: SubmitSingleOrBulkBurnTransactionInput!) {
                submitSingleOrBulkBurnTransaction(input: $input) {
                    transactionId
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const submitSingleOrBulkBurnTransactionPayload: SubmitSingleOrBulkBurnTransactionPayload =
        response?.submitSingleOrBulkBurnTransaction || {};
    return submitSingleOrBulkBurnTransactionPayload;
};
