import { GetPotentialRewardsInput } from '../../../types/StakeProject/GetPotentialRewards/GetPotentialRewardsInput';
import { GetPotentialRewardsPayload } from '../../../types/StakeProject/GetPotentialRewards/GetPotentialRewardsPayload';
import { GetRemainingTokensPayload } from '../../../types/StakeProject/GetPotentialRewards/GetRemainingTokens/GetRemainingTokensPayload';
import { GetStakedNFTCountsPayload } from '@/types/StakeProject/GetStakedNftCounts/GetStakedNftCountsPayload';
import { gql } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';

//---------------------------------------------------------------------------------------------------//
// Token Get Amount Functions
//---------------------------------------------------------------------------------------------------//
//export const graphQLClient = new GraphQLClient("https://api.preprod.saturnnft.io/graphql/");
export const graphQLClient = new GraphQLClient("https://api.saturnnft.io/graphql/");
//export const graphQLClient = new GraphQLClient("https://localhost:5001/graphql/");

export const getGraphQLHeaders = () => {
    const headers: any = {} as HeadersInit;
    try {
        const apiKey = 'ce188fe7-84d6-44f9-ac72-af09c9c62671-825c168d-fa34-4520-86ae-7542dac3dad5';
        headers['Authorization'] = `Bearer ${apiKey}`;
        return headers;
    } catch (error) {
        console.error(error);
        return headers;
    }
};

export const queryPotentialRewards = async (input: GetPotentialRewardsInput) => {
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            query PotentialRewards($input: GetPotentialRewardsInput!) {
                potentialRewards(input: $input) {
                    nftStakeRewardsPayloads {
                        stakeProjectId
                        policyId
                        assetName
                        rewards
                        daysStaked
                        spend_utxo_status
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const getPotentialRewardsPayload: GetPotentialRewardsPayload = response?.potentialRewards;
    const getPotentialRewards: any = getPotentialRewardsPayload?.nftStakeRewardsPayloads || {};
    return getPotentialRewards;
};

export const queryRemainingTokens = async (id: string) => {
    if (!id) return null;
    const input = { id: id };
    const response: any = await graphQLClient.request(
        gql`
            query RemainingTokens($id: String!) {
                remainingTokens(id: $id) {
                    tokens
                    error {
                        message
                    }
                }
            }
        `,
        input
    );

    const getRemainingTokensPayload: GetRemainingTokensPayload = response?.remainingTokens;
    const getRemainingTokens: any = getRemainingTokensPayload?.tokens || {};
    return getRemainingTokens;
};

export const queryStakedNfts = async (id: string) => {
    if (!id) return null;
    const input = { id: id };
    const response: any = await graphQLClient.request(
        gql`
            query StakedNfts($id: String!) {
                stakedNfts(id: $id) {
                    nfts {
                        policyId
                        assetName
                        rewardsAccumulated
                        daysStaked
                    }
                    error {
                        message
                    }
                }
            }
        `,
        input
    );

    const getStakedNftsPayload: GetStakedNFTsPayload = response?.stakedNfts;
    const getStakedNfts: any = getStakedNftsPayload?.nfts || {};
    return getStakedNfts;
};

export const queryStakedNftCounts = async (id: string) => {
    if (!id) return null;
    graphQLClient.setHeaders(await getGraphQLHeaders());
    const input = { id: id };
    const response: any = await graphQLClient.request(
        gql`
            query StakedNftCounts($id: String!) {
                stakedNftCounts(id: $id) {
                    stakedNftCounts {
                        key
                        value
                    }
                    error {
                        message
                    }
                }
            }
        `,
        input
    );

    const getStakedNftCountsPayload: GetStakedNFTCountsPayload = response?.stakedNftCounts;
    const getStakedNftCounts: any = getStakedNftCountsPayload?.stakedNftCounts || {};
    return getStakedNftCounts;
};

export const queryStakedNftMainPairs = async (input: GetStakedNFTMainPairsInput) => {
    graphQLClient.setHeaders(await getGraphQLHeaders());
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            query StakedNftMainPairs($input: GetStakedNFTMainPairsInput!) {
                stakedNftMainPairs(input: $input) {
                    stakedNFTPairPayloads {
                        stakeProjectId
                        mainPolicyId
                        mainAssetName
                        partnerPolicyId
                        partnerAssetName
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const getStakedNftMainPairsPayload: GetStakedNFTMainPairsPayload = response?.stakedNftMainPairs;
    const getStakedNFTPairPayloads: any = getStakedNftMainPairsPayload?.stakedNFTPairPayloads || {};
    return getStakedNFTPairPayloads;
};

export const queryStakedNftPartnerPairs = async (input: GetStakedNFTPartnerPairsInput) => {
    graphQLClient.setHeaders(await getGraphQLHeaders());
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            query StakedNftPartnerPairs($input: GetStakedNFTPartnerPairsInput!) {
                stakedNftPartnerPairs(input: $input) {
                    stakedNFTPairPayloads {
                        stakeProjectId
                        mainPolicyId
                        mainAssetName
                        partnerPolicyId
                        partnerAssetName
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const getStakedNftPartnerPairsPayload: GetStakedNFTPartnerPairsPayload = response?.stakedNftPartnerPairs;
    const getStakedNFTPairPayloads: any = getStakedNftPartnerPairsPayload?.stakedNFTPairPayloads || {};
    return getStakedNFTPairPayloads;
};

export const queryDailyRewards = async (input: GetDailyRewardsInput) => {
    graphQLClient.setHeaders(await getGraphQLHeaders());
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            query DailyRewards($input: GetDailyRewardsInput!) {
                dailyRewards(input: $input) {
                    nftDailyStakeRewardsPayloads {
                        stakeProjectId
                        policyId
                        assetName
                        rewards
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const getDailyRewardsPayload: GetDailyRewardsPayload = response?.dailyRewards;
    const getDailyRewards: any = getDailyRewardsPayload?.nftDailyStakeRewardsPayloads || {};
    return getDailyRewards;
};

export const queryPotentialRewardsWithPending = async (input: GetPotentialRewardsWithPendingInput) => {
    graphQLClient.setHeaders(await getGraphQLHeaders());
    const parameters = { input: input };
    const response: any = await graphQLClient.request(
        gql`
            query PotentialRewardsWithPending($input: GetPotentialRewardsWithPendingInput!) {
                potentialRewardsWithPending(input: $input) {
                    nftStakeRewardsWithPendingPayloads {
                        stakeProjectId
                        policyId
                        assetName
                        rewards
                        daysStaked
                        active_utxo_status
                        spend_utxo_status
                    }
                    error {
                        message
                    }
                }
            }
        `,
        parameters
    );

    const getPotentialRewardsWithPendingPayload: GetPotentialRewardsWithPendingPayload = response?.potentialRewardsWithPending;
    const getPotentialRewardsWithPending: any = getPotentialRewardsWithPendingPayload?.nftStakeRewardsWithPendingPayloads || {};
    return getPotentialRewardsWithPending;
};

//---------------------------------------------------------------------------------------------------//
